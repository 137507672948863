// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/types/locale.proto (package kikoff_canada.protobuf.types, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum kikoff_canada.protobuf.types.Locale
 */
export enum Locale {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: en_CA = 1;
   */
  en_CA = 1,

  /**
   * @generated from enum value: fr_CA = 2;
   */
  fr_CA = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Locale)
proto3.util.setEnumType(Locale, "kikoff_canada.protobuf.types.Locale", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "en_CA" },
  { no: 2, name: "fr_CA" },
]);

