// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/user.proto (package kikoff_canada.protobuf.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Locale } from "../types/locale_pb.js";
import { FullName } from "../types/pii/full_name_pb.js";
import { Address } from "../types/pii/address_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.models.User
 */
export class User extends Message<User> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: kikoff_canada.protobuf.models.User.OnboardingStatus onboarding_status = 2;
   */
  onboardingStatus = User.OnboardingStatus.UNKNOWN;

  /**
   * @generated from field: kikoff_canada.protobuf.models.User.Pii pii = 3;
   */
  pii?: User.Pii;

  /**
   * @generated from field: bool active = 4;
   */
  active = false;

  /**
   * @generated from field: kikoff_canada.protobuf.types.Locale locale = 5;
   */
  locale = Locale.UNKNOWN;

  /**
   * deprecated
   *
   * @generated from field: repeated kikoff_canada.protobuf.models.User.Dismissible dismissibles = 6;
   */
  dismissibles: User.Dismissible[] = [];

  constructor(data?: PartialMessage<User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "onboarding_status", kind: "enum", T: proto3.getEnumType(User.OnboardingStatus) },
    { no: 3, name: "pii", kind: "message", T: User.Pii },
    { no: 4, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "locale", kind: "enum", T: proto3.getEnumType(Locale) },
    { no: 6, name: "dismissibles", kind: "enum", T: proto3.getEnumType(User.Dismissible), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User {
    return new User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User {
    return new User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User {
    return new User().fromJsonString(jsonString, options);
  }

  static equals(a: User | PlainMessage<User> | undefined, b: User | PlainMessage<User> | undefined): boolean {
    return proto3.util.equals(User, a, b);
  }

  static _enums: {
    OnboardingStatus: typeof User.OnboardingStatus,
    Dismissible: typeof User.Dismissible,
  };
}

export namespace User {
  /**
   * @generated from enum kikoff_canada.protobuf.models.User.OnboardingStatus
   */
  export enum OnboardingStatus {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: ONBOARDING = 1;
     */
    ONBOARDING = 1,

    /**
     * @generated from enum value: ONBOARDED = 2;
     */
    ONBOARDED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(User.OnboardingStatus)
  proto3.util.setEnumType(User.OnboardingStatus, "kikoff_canada.protobuf.models.User.OnboardingStatus", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ONBOARDING" },
    { no: 2, name: "ONBOARDED" },
  ]);
}

export namespace User {
  /**
   * deprecated
   *
   * @generated from enum kikoff_canada.protobuf.models.User.Dismissible
   */
  export enum Dismissible {
    /**
     * @generated from enum value: DISMISSIBLE_UNKNOWN = 0;
     */
    DISMISSIBLE_UNKNOWN = 0,

    /**
     * @generated from enum value: DOWNLOAD_APP_REMINDER = 1;
     */
    DOWNLOAD_APP_REMINDER = 1,
  }
  // Retrieve enum metadata with: proto3.getEnumType(User.Dismissible)
  proto3.util.setEnumType(User.Dismissible, "kikoff_canada.protobuf.models.User.Dismissible", [
    { no: 0, name: "DISMISSIBLE_UNKNOWN" },
    { no: 1, name: "DOWNLOAD_APP_REMINDER" },
  ]);
}

export namespace User {
  /**
   * @generated from message kikoff_canada.protobuf.models.User.Pii
   */
  export class Pii extends Message<User.Pii> {
    /**
     * @generated from field: string email = 1;
     */
    email = "";

    /**
     * @generated from field: kikoff_canada.protobuf.types.pii.FullName name = 2;
     */
    name?: FullName;

    /**
     * @generated from field: string phone = 3;
     */
    phone = "";

    /**
     * @generated from field: kikoff_canada.protobuf.types.pii.Address address = 4;
     */
    address?: Address;

    /**
     * @generated from field: bool email_confirmed = 5;
     */
    emailConfirmed = false;

    constructor(data?: PartialMessage<User.Pii>) {
      super();
      proto3.util.initPartial(data, this);
    }

    static readonly runtime: typeof proto3 = proto3;
    static readonly typeName = "kikoff_canada.protobuf.models.User.Pii";
    static readonly fields: FieldList = proto3.util.newFieldList(() => [
      { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 2, name: "name", kind: "message", T: FullName },
      { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
      { no: 4, name: "address", kind: "message", T: Address },
      { no: 5, name: "email_confirmed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    ]);

    static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): User.Pii {
      return new User.Pii().fromBinary(bytes, options);
    }

    static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): User.Pii {
      return new User.Pii().fromJson(jsonValue, options);
    }

    static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): User.Pii {
      return new User.Pii().fromJsonString(jsonString, options);
    }

    static equals(a: User.Pii | PlainMessage<User.Pii> | undefined, b: User.Pii | PlainMessage<User.Pii> | undefined): boolean {
      return proto3.util.equals(User.Pii, a, b);
    }

    static _enums: {
    };
  }
}

