// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/subscription_plan.proto (package kikoff_canada.protobuf.models, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { CreditLineAgreement } from "./lending/credit_line_agreement_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.models.SubscriptionPlan
 */
export class SubscriptionPlan extends Message<SubscriptionPlan> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: kikoff_canada.protobuf.models.SubscriptionPlan.Tier tier = 2;
   */
  tier = SubscriptionPlan.Tier.UNKNOWN;

  /**
   * @generated from field: uint32 monthly_cost_cents = 3;
   */
  monthlyCostCents = 0;

  /**
   * @generated from field: uint32 duration_months = 4;
   */
  durationMonths = 0;

  /**
   * @generated from field: kikoff_canada.protobuf.models.lending.CreditLineAgreement credit_line_agreement = 5;
   */
  creditLineAgreement?: CreditLineAgreement;

  constructor(data?: PartialMessage<SubscriptionPlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.SubscriptionPlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tier", kind: "enum", T: proto3.getEnumType(SubscriptionPlan.Tier) },
    { no: 3, name: "monthly_cost_cents", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 4, name: "duration_months", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "credit_line_agreement", kind: "message", T: CreditLineAgreement },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SubscriptionPlan {
    return new SubscriptionPlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SubscriptionPlan {
    return new SubscriptionPlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SubscriptionPlan {
    return new SubscriptionPlan().fromJsonString(jsonString, options);
  }

  static equals(a: SubscriptionPlan | PlainMessage<SubscriptionPlan> | undefined, b: SubscriptionPlan | PlainMessage<SubscriptionPlan> | undefined): boolean {
    return proto3.util.equals(SubscriptionPlan, a, b);
  }

  static _enums: {
    Tier: typeof SubscriptionPlan.Tier,
  };
}

export namespace SubscriptionPlan {
  /**
   * @generated from enum kikoff_canada.protobuf.models.SubscriptionPlan.Tier
   */
  export enum Tier {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: BASIC = 1;
     */
    BASIC = 1,

    /**
     * @generated from enum value: PREMIUM = 2;
     */
    PREMIUM = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(SubscriptionPlan.Tier)
  proto3.util.setEnumType(SubscriptionPlan.Tier, "kikoff_canada.protobuf.models.SubscriptionPlan.Tier", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "BASIC" },
    { no: 2, name: "PREMIUM" },
  ]);
}

