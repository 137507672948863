// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/asgard/credit_line_details.proto (package kikoff_canada.protobuf.models.asgard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { CreditLine } from "../lending/credit_line_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.models.asgard.CreditLineDetails
 */
export class CreditLineDetails extends Message<CreditLineDetails> {
  /**
   * @generated from field: kikoff_canada.protobuf.models.lending.CreditLine credit_line = 1;
   */
  creditLine?: CreditLine;

  /**
   * @generated from field: google.protobuf.Timestamp furnished_at = 2;
   */
  furnishedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp furnish_confirmed_at = 3;
   */
  furnishConfirmedAt?: Timestamp;

  constructor(data?: PartialMessage<CreditLineDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.asgard.CreditLineDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "credit_line", kind: "message", T: CreditLine },
    { no: 2, name: "furnished_at", kind: "message", T: Timestamp },
    { no: 3, name: "furnish_confirmed_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreditLineDetails {
    return new CreditLineDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreditLineDetails {
    return new CreditLineDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreditLineDetails {
    return new CreditLineDetails().fromJsonString(jsonString, options);
  }

  static equals(a: CreditLineDetails | PlainMessage<CreditLineDetails> | undefined, b: CreditLineDetails | PlainMessage<CreditLineDetails> | undefined): boolean {
    return proto3.util.equals(CreditLineDetails, a, b);
  }

  static _enums: {
  };
}

