// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/types/pii/full_name.proto (package kikoff_canada.protobuf.types.pii, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.types.pii.FullName
 */
export class FullName extends Message<FullName> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: optional string middle_name = 2;
   */
  middleName?: string;

  /**
   * @generated from field: string last_name = 3;
   */
  lastName = "";

  constructor(data?: PartialMessage<FullName>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.types.pii.FullName";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "middle_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FullName {
    return new FullName().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FullName {
    return new FullName().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FullName {
    return new FullName().fromJsonString(jsonString, options);
  }

  static equals(a: FullName | PlainMessage<FullName> | undefined, b: FullName | PlainMessage<FullName> | undefined): boolean {
    return proto3.util.equals(FullName, a, b);
  }

  static _enums: {
  };
}

