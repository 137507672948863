// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/services/asgard/users_service.proto (package kikoff_canada.protobuf.services.asgard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Error } from "../../types/error_pb.js";
import { User } from "../../models/user_pb.js";
import { UserDetails } from "../../models/asgard/user_details_pb.js";
import { CreditLineDetails } from "../../models/asgard/credit_line_details_pb.js";
import { UserSubscription } from "../../models/user_subscription_pb.js";
import { ManualIdvAttempt } from "../../models/pii/manual_idv_attempt_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersSearchRequest
 */
export class UsersSearchRequest extends Message<UsersSearchRequest> {
  /**
   * @generated from field: optional string user_id = 1;
   */
  userId?: string;

  /**
   * @generated from field: optional string email = 2;
   */
  email?: string;

  /**
   * @generated from field: optional string phone = 3;
   */
  phone?: string;

  /**
   * @generated from field: optional string first_name = 4;
   */
  firstName?: string;

  /**
   * @generated from field: optional string last_name = 5;
   */
  lastName?: string;

  /**
   * @generated from field: optional string address_line_1 = 6;
   */
  addressLine1?: string;

  /**
   * @generated from field: optional string postal_code = 7;
   */
  postalCode?: string;

  /**
   * @generated from field: optional string credit_line_id = 8;
   */
  creditLineId?: string;

  constructor(data?: PartialMessage<UsersSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "credit_line_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersSearchRequest {
    return new UsersSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersSearchRequest {
    return new UsersSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersSearchRequest {
    return new UsersSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersSearchRequest | PlainMessage<UsersSearchRequest> | undefined, b: UsersSearchRequest | PlainMessage<UsersSearchRequest> | undefined): boolean {
    return proto3.util.equals(UsersSearchRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersSearchResponse
 */
export class UsersSearchResponse extends Message<UsersSearchResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: repeated kikoff_canada.protobuf.models.User users = 2;
   */
  users: User[] = [];

  constructor(data?: PartialMessage<UsersSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "users", kind: "message", T: User, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersSearchResponse {
    return new UsersSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersSearchResponse {
    return new UsersSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersSearchResponse {
    return new UsersSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersSearchResponse | PlainMessage<UsersSearchResponse> | undefined, b: UsersSearchResponse | PlainMessage<UsersSearchResponse> | undefined): boolean {
    return proto3.util.equals(UsersSearchResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersShowRequest
 */
export class UsersShowRequest extends Message<UsersShowRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<UsersShowRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersShowRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersShowRequest {
    return new UsersShowRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersShowRequest {
    return new UsersShowRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersShowRequest {
    return new UsersShowRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersShowRequest | PlainMessage<UsersShowRequest> | undefined, b: UsersShowRequest | PlainMessage<UsersShowRequest> | undefined): boolean {
    return proto3.util.equals(UsersShowRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersShowResponse
 */
export class UsersShowResponse extends Message<UsersShowResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: kikoff_canada.protobuf.models.asgard.UserDetails user_details = 2;
   */
  userDetails?: UserDetails;

  /**
   * @generated from field: repeated kikoff_canada.protobuf.models.asgard.CreditLineDetails credit_line_details = 3;
   */
  creditLineDetails: CreditLineDetails[] = [];

  /**
   * @generated from field: repeated kikoff_canada.protobuf.models.UserSubscription subscriptions = 4;
   */
  subscriptions: UserSubscription[] = [];

  /**
   * @generated from field: repeated string raw_idv_payloads = 5;
   */
  rawIdvPayloads: string[] = [];

  constructor(data?: PartialMessage<UsersShowResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersShowResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "user_details", kind: "message", T: UserDetails },
    { no: 3, name: "credit_line_details", kind: "message", T: CreditLineDetails, repeated: true },
    { no: 4, name: "subscriptions", kind: "message", T: UserSubscription, repeated: true },
    { no: 5, name: "raw_idv_payloads", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersShowResponse {
    return new UsersShowResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersShowResponse {
    return new UsersShowResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersShowResponse {
    return new UsersShowResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersShowResponse | PlainMessage<UsersShowResponse> | undefined, b: UsersShowResponse | PlainMessage<UsersShowResponse> | undefined): boolean {
    return proto3.util.equals(UsersShowResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersAllowIdvRetryRequest
 */
export class UsersAllowIdvRetryRequest extends Message<UsersAllowIdvRetryRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<UsersAllowIdvRetryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersAllowIdvRetryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersAllowIdvRetryRequest {
    return new UsersAllowIdvRetryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersAllowIdvRetryRequest {
    return new UsersAllowIdvRetryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersAllowIdvRetryRequest {
    return new UsersAllowIdvRetryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersAllowIdvRetryRequest | PlainMessage<UsersAllowIdvRetryRequest> | undefined, b: UsersAllowIdvRetryRequest | PlainMessage<UsersAllowIdvRetryRequest> | undefined): boolean {
    return proto3.util.equals(UsersAllowIdvRetryRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersAllowIdvRetryResponse
 */
export class UsersAllowIdvRetryResponse extends Message<UsersAllowIdvRetryResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  constructor(data?: PartialMessage<UsersAllowIdvRetryResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersAllowIdvRetryResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersAllowIdvRetryResponse {
    return new UsersAllowIdvRetryResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersAllowIdvRetryResponse {
    return new UsersAllowIdvRetryResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersAllowIdvRetryResponse {
    return new UsersAllowIdvRetryResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersAllowIdvRetryResponse | PlainMessage<UsersAllowIdvRetryResponse> | undefined, b: UsersAllowIdvRetryResponse | PlainMessage<UsersAllowIdvRetryResponse> | undefined): boolean {
    return proto3.util.equals(UsersAllowIdvRetryResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersActivateRequest
 */
export class UsersActivateRequest extends Message<UsersActivateRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<UsersActivateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersActivateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersActivateRequest {
    return new UsersActivateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersActivateRequest {
    return new UsersActivateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersActivateRequest {
    return new UsersActivateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersActivateRequest | PlainMessage<UsersActivateRequest> | undefined, b: UsersActivateRequest | PlainMessage<UsersActivateRequest> | undefined): boolean {
    return proto3.util.equals(UsersActivateRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersActivateResponse
 */
export class UsersActivateResponse extends Message<UsersActivateResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  constructor(data?: PartialMessage<UsersActivateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersActivateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersActivateResponse {
    return new UsersActivateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersActivateResponse {
    return new UsersActivateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersActivateResponse {
    return new UsersActivateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersActivateResponse | PlainMessage<UsersActivateResponse> | undefined, b: UsersActivateResponse | PlainMessage<UsersActivateResponse> | undefined): boolean {
    return proto3.util.equals(UsersActivateResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersDeactivateRequest
 */
export class UsersDeactivateRequest extends Message<UsersDeactivateRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  constructor(data?: PartialMessage<UsersDeactivateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersDeactivateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersDeactivateRequest {
    return new UsersDeactivateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersDeactivateRequest {
    return new UsersDeactivateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersDeactivateRequest {
    return new UsersDeactivateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersDeactivateRequest | PlainMessage<UsersDeactivateRequest> | undefined, b: UsersDeactivateRequest | PlainMessage<UsersDeactivateRequest> | undefined): boolean {
    return proto3.util.equals(UsersDeactivateRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersDeactivateResponse
 */
export class UsersDeactivateResponse extends Message<UsersDeactivateResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  constructor(data?: PartialMessage<UsersDeactivateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersDeactivateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersDeactivateResponse {
    return new UsersDeactivateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersDeactivateResponse {
    return new UsersDeactivateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersDeactivateResponse {
    return new UsersDeactivateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersDeactivateResponse | PlainMessage<UsersDeactivateResponse> | undefined, b: UsersDeactivateResponse | PlainMessage<UsersDeactivateResponse> | undefined): boolean {
    return proto3.util.equals(UsersDeactivateResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersListPendingManualIdvAttemptsResponse
 */
export class UsersListPendingManualIdvAttemptsResponse extends Message<UsersListPendingManualIdvAttemptsResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: repeated kikoff_canada.protobuf.models.pii.ManualIdvAttempt manual_idv_attempts = 2;
   */
  manualIdvAttempts: ManualIdvAttempt[] = [];

  constructor(data?: PartialMessage<UsersListPendingManualIdvAttemptsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersListPendingManualIdvAttemptsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "manual_idv_attempts", kind: "message", T: ManualIdvAttempt, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersListPendingManualIdvAttemptsResponse {
    return new UsersListPendingManualIdvAttemptsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersListPendingManualIdvAttemptsResponse {
    return new UsersListPendingManualIdvAttemptsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersListPendingManualIdvAttemptsResponse {
    return new UsersListPendingManualIdvAttemptsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersListPendingManualIdvAttemptsResponse | PlainMessage<UsersListPendingManualIdvAttemptsResponse> | undefined, b: UsersListPendingManualIdvAttemptsResponse | PlainMessage<UsersListPendingManualIdvAttemptsResponse> | undefined): boolean {
    return proto3.util.equals(UsersListPendingManualIdvAttemptsResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusRequest
 */
export class UsersSubmitManualRiskStatusRequest extends Message<UsersSubmitManualRiskStatusRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusRequest.RiskStatus risk_status = 2;
   */
  riskStatus = UsersSubmitManualRiskStatusRequest.RiskStatus.UNKNOWN;

  /**
   * @generated from field: string failure_reason = 3;
   */
  failureReason = "";

  constructor(data?: PartialMessage<UsersSubmitManualRiskStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "risk_status", kind: "enum", T: proto3.getEnumType(UsersSubmitManualRiskStatusRequest.RiskStatus) },
    { no: 3, name: "failure_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersSubmitManualRiskStatusRequest {
    return new UsersSubmitManualRiskStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersSubmitManualRiskStatusRequest {
    return new UsersSubmitManualRiskStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersSubmitManualRiskStatusRequest {
    return new UsersSubmitManualRiskStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersSubmitManualRiskStatusRequest | PlainMessage<UsersSubmitManualRiskStatusRequest> | undefined, b: UsersSubmitManualRiskStatusRequest | PlainMessage<UsersSubmitManualRiskStatusRequest> | undefined): boolean {
    return proto3.util.equals(UsersSubmitManualRiskStatusRequest, a, b);
  }

  static _enums: {
    RiskStatus: typeof UsersSubmitManualRiskStatusRequest.RiskStatus,
  };
}

export namespace UsersSubmitManualRiskStatusRequest {
  /**
   * @generated from enum kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusRequest.RiskStatus
   */
  export enum RiskStatus {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: PASSED = 1;
     */
    PASSED = 1,

    /**
     * @generated from enum value: FAILED = 2;
     */
    FAILED = 2,

    /**
     * @generated from enum value: NEEDS_REUPLOAD = 3;
     */
    NEEDS_REUPLOAD = 3,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UsersSubmitManualRiskStatusRequest.RiskStatus)
  proto3.util.setEnumType(UsersSubmitManualRiskStatusRequest.RiskStatus, "kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusRequest.RiskStatus", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "PASSED" },
    { no: 2, name: "FAILED" },
    { no: 3, name: "NEEDS_REUPLOAD" },
  ]);
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusResponse
 */
export class UsersSubmitManualRiskStatusResponse extends Message<UsersSubmitManualRiskStatusResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: repeated kikoff_canada.protobuf.models.pii.ManualIdvAttempt manual_idv_attempts = 2;
   */
  manualIdvAttempts: ManualIdvAttempt[] = [];

  constructor(data?: PartialMessage<UsersSubmitManualRiskStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersSubmitManualRiskStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "manual_idv_attempts", kind: "message", T: ManualIdvAttempt, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersSubmitManualRiskStatusResponse {
    return new UsersSubmitManualRiskStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersSubmitManualRiskStatusResponse {
    return new UsersSubmitManualRiskStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersSubmitManualRiskStatusResponse {
    return new UsersSubmitManualRiskStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersSubmitManualRiskStatusResponse | PlainMessage<UsersSubmitManualRiskStatusResponse> | undefined, b: UsersSubmitManualRiskStatusResponse | PlainMessage<UsersSubmitManualRiskStatusResponse> | undefined): boolean {
    return proto3.util.equals(UsersSubmitManualRiskStatusResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersImpersonateRequest
 */
export class UsersImpersonateRequest extends Message<UsersImpersonateRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: kikoff_canada.protobuf.services.asgard.UsersImpersonateRequest.ReadOnlyMode read_only_mode = 2;
   */
  readOnlyMode = UsersImpersonateRequest.ReadOnlyMode.UNKNOWN;

  constructor(data?: PartialMessage<UsersImpersonateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersImpersonateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "read_only_mode", kind: "enum", T: proto3.getEnumType(UsersImpersonateRequest.ReadOnlyMode) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersImpersonateRequest {
    return new UsersImpersonateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersImpersonateRequest {
    return new UsersImpersonateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersImpersonateRequest {
    return new UsersImpersonateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersImpersonateRequest | PlainMessage<UsersImpersonateRequest> | undefined, b: UsersImpersonateRequest | PlainMessage<UsersImpersonateRequest> | undefined): boolean {
    return proto3.util.equals(UsersImpersonateRequest, a, b);
  }

  static _enums: {
    ReadOnlyMode: typeof UsersImpersonateRequest.ReadOnlyMode,
  };
}

export namespace UsersImpersonateRequest {
  /**
   * @generated from enum kikoff_canada.protobuf.services.asgard.UsersImpersonateRequest.ReadOnlyMode
   */
  export enum ReadOnlyMode {
    /**
     * @generated from enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,

    /**
     * @generated from enum value: ENABLED = 1;
     */
    ENABLED = 1,

    /**
     * @generated from enum value: DISABLED = 2;
     */
    DISABLED = 2,
  }
  // Retrieve enum metadata with: proto3.getEnumType(UsersImpersonateRequest.ReadOnlyMode)
  proto3.util.setEnumType(UsersImpersonateRequest.ReadOnlyMode, "kikoff_canada.protobuf.services.asgard.UsersImpersonateRequest.ReadOnlyMode", [
    { no: 0, name: "UNKNOWN" },
    { no: 1, name: "ENABLED" },
    { no: 2, name: "DISABLED" },
  ]);
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersImpersonateResponse
 */
export class UsersImpersonateResponse extends Message<UsersImpersonateResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  constructor(data?: PartialMessage<UsersImpersonateResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersImpersonateResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersImpersonateResponse {
    return new UsersImpersonateResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersImpersonateResponse {
    return new UsersImpersonateResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersImpersonateResponse {
    return new UsersImpersonateResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersImpersonateResponse | PlainMessage<UsersImpersonateResponse> | undefined, b: UsersImpersonateResponse | PlainMessage<UsersImpersonateResponse> | undefined): boolean {
    return proto3.util.equals(UsersImpersonateResponse, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersSetManualPendingIdvAttemptRequest
 */
export class UsersSetManualPendingIdvAttemptRequest extends Message<UsersSetManualPendingIdvAttemptRequest> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<UsersSetManualPendingIdvAttemptRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersSetManualPendingIdvAttemptRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersSetManualPendingIdvAttemptRequest {
    return new UsersSetManualPendingIdvAttemptRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersSetManualPendingIdvAttemptRequest {
    return new UsersSetManualPendingIdvAttemptRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersSetManualPendingIdvAttemptRequest {
    return new UsersSetManualPendingIdvAttemptRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UsersSetManualPendingIdvAttemptRequest | PlainMessage<UsersSetManualPendingIdvAttemptRequest> | undefined, b: UsersSetManualPendingIdvAttemptRequest | PlainMessage<UsersSetManualPendingIdvAttemptRequest> | undefined): boolean {
    return proto3.util.equals(UsersSetManualPendingIdvAttemptRequest, a, b);
  }

  static _enums: {
  };
}

/**
 * @generated from message kikoff_canada.protobuf.services.asgard.UsersSetManualPendingIdvAttemptResponse
 */
export class UsersSetManualPendingIdvAttemptResponse extends Message<UsersSetManualPendingIdvAttemptResponse> {
  /**
   * @generated from field: repeated kikoff_canada.protobuf.types.Error errors = 1;
   */
  errors: Error[] = [];

  /**
   * @generated from field: kikoff_canada.protobuf.models.pii.ManualIdvAttempt manual_idv_attempt = 2;
   */
  manualIdvAttempt?: ManualIdvAttempt;

  constructor(data?: PartialMessage<UsersSetManualPendingIdvAttemptResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.services.asgard.UsersSetManualPendingIdvAttemptResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: Error, repeated: true },
    { no: 2, name: "manual_idv_attempt", kind: "message", T: ManualIdvAttempt },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UsersSetManualPendingIdvAttemptResponse {
    return new UsersSetManualPendingIdvAttemptResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UsersSetManualPendingIdvAttemptResponse {
    return new UsersSetManualPendingIdvAttemptResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UsersSetManualPendingIdvAttemptResponse {
    return new UsersSetManualPendingIdvAttemptResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UsersSetManualPendingIdvAttemptResponse | PlainMessage<UsersSetManualPendingIdvAttemptResponse> | undefined, b: UsersSetManualPendingIdvAttemptResponse | PlainMessage<UsersSetManualPendingIdvAttemptResponse> | undefined): boolean {
    return proto3.util.equals(UsersSetManualPendingIdvAttemptResponse, a, b);
  }

  static _enums: {
  };
}

