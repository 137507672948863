// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/models/asgard/user_details.proto (package kikoff_canada.protobuf.models.asgard, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { User } from "../user_pb.js";

/**
 * @generated from message kikoff_canada.protobuf.models.asgard.UserDetails
 */
export class UserDetails extends Message<UserDetails> {
  /**
   * @generated from field: kikoff_canada.protobuf.models.User user = 1;
   */
  user?: User;

  /**
   * @generated from field: string dob = 2;
   */
  dob = "";

  constructor(data?: PartialMessage<UserDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.models.asgard.UserDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "dob", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserDetails {
    return new UserDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserDetails {
    return new UserDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserDetails {
    return new UserDetails().fromJsonString(jsonString, options);
  }

  static equals(a: UserDetails | PlainMessage<UserDetails> | undefined, b: UserDetails | PlainMessage<UserDetails> | undefined): boolean {
    return proto3.util.equals(UserDetails, a, b);
  }

  static _enums: {
  };
}

