// @generated by protoc-gen-es-ns v0.0.0 with parameter "target=ts"
// @generated from file kikoff_canada/protobuf/types/pii/address.proto (package kikoff_canada.protobuf.types.pii, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message kikoff_canada.protobuf.types.pii.Address
 */
export class Address extends Message<Address> {
  /**
   * Number + street
   *
   * @generated from field: string address_line_1 = 1;
   */
  addressLine1 = "";

  /**
   * Apt, Ste, etc...
   *
   * @generated from field: optional string address_line_2 = 2;
   */
  addressLine2?: string;

  /**
   * City/town
   *
   * @generated from field: string locality = 3;
   */
  locality = "";

  /**
   * State/province
   *
   * @generated from field: string subdivision = 4;
   */
  subdivision = "";

  /**
   * Zip code
   *
   * @generated from field: string postal_code = 5;
   */
  postalCode = "";

  /**
   * @generated from field: string country = 6;
   */
  country = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "kikoff_canada.protobuf.types.pii.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "locality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subdivision", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }

  static _enums: {
  };
}

